import { Link } from "gatsby";
import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/css/bootstrap.css";
import { Modal, Button } from "antd";
//import Layout from "../components/layout";
import Loginform from "../pages/loginform"
import axios from "axios";
//import { NONAME } from "dns";
require("./header.css");
require("../css/responsive.scss");
require("../css/business.css");


var onLoadMainCatArray = [],
  onLoadCat = [];
var imgArray = [
  "https://res.cloudinary.com/djoztpm50/image/upload/v1563273958/Native%20Circle/fsc1-min_copy_2.png",
  "https://res.cloudinary.com/djoztpm50/image/upload/v1563275259/Native%20Circle/fsc1-min_copy.png",
  "https://res.cloudinary.com/djoztpm50/image/upload/v1563273958/Native%20Circle/fsc1-min.png"
];
var initialData = [];

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      color: "red",
      visible: false,
      screenWidth: "",
      headerClass: "headerbackgroundNew",
      // headerClass1: "headerhideonscroll1",
      display: "block",
      displaySticky: "none",
      navClassName: "navbar-toggle",
      menuColor: "menuStickyColor",
      menuStickySignup: "",
      menu: false,
      navbarClassName: "navbar-dark",
      backgroundColor: "navbar-black",
      onLoadMainCatArray1: [],

      loading: false,
      displayEmail: "none",
      displayEmailInvalid: "none",
      displayPassword: "none",
      profiledisplay: "none",
      logindisplay: "block",
      loginstatus: "",
      loginmail: "",
      loggedinname: "",
      mainCatV2 : [],
      allFilterData: {},
      catV2: []
    };
    this.toggleMenu = this.toggleMenu.bind(this);
    this.logout = this.logout.bind(this);
    // this.saveData = this.saveData.bind(this);
  }
  // anil code //

  logout() {
    this.setState({ profiledisplay: "none", logindisplay: "block" });
    window.sessionStorage.clear();

    //  this.setState({logindisplay:"block"});
  }

  //  componentWillMount()
  //  {
  //    if(window.sessionStorage.getItem("isLoggedIn")=="true")
  //    {
  //      this.setState({profiledisplay:"block"});
  //      this.setState({logindisplay:"none"});
  //    }
  //    else
  //    {
  //      this.setState({profiledisplay:"none"});
  //      this.setState({logindisplay:"block"});
  //    }

  //  }


  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk() {
    var _this = this;
    var bodyFormData = new FormData();


    var email = document.getElementById("email").value;
    var password = document.getElementById("password").value




    var stringify;



    if (email == "") {
      this.validateEmail();
    }
    else if (password == "") {
      this.validatePassword();
    }
    else {
      bodyFormData.set("email", email);
      bodyFormData.set("password", password);




      //console.log(_this);
      axios
        .post(
          "https://dashboard.nativecircle.in/index.php/wp-json/contact-form-7/v1/contact-forms/190/feedback",
          bodyFormData,
          {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          }
        )
        .then(response => {
          //  console.log(response.status);
          document.getElementById("form").reset();
          if (response.status == 200 || response.status == 201) {
            _this.setState({

              loading: false
            });
          }
        })
        .catch(err => {
          //  console.log(err);
        });
    }
  }

  handleCancel = e => {
    // console.log(e);
    this.setState({
      visible: false,
    });
  };

  validateEmail() {
    var pattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    var email = document.getElementById("email").value;
    if (email == "") {
      this.setState({ displayEmail: "block" });
    } else {
      this.setState({ displayEmail: "none" });
      if (!pattern.test(email)) {
        this.setState({ displayEmailInvalid: "block" });
      } else {
        this.setState({ displayEmailInvalid: "none" });
      }
    }
  }



  validatePassword() {

    var password = document.getElementById("password").value;
    if (password == "") {
      this.setState({ displayPassword: "block" });
    }
    else {
      this.setState({ displayPassword: "none" });
    }
  }



  //-------------anil code end--------//




  componentDidUpdate(prevProps) {
    // window.localStorage.setItem("category", "allData");
    onLoadMainCatArray = JSON.parse(
      window.localStorage.getItem("onLoadMainCatArray")
    );

    if (prevProps.mainCatV2 != null 
        && prevProps.mainCatV2 instanceof Array
        && this.props.mainCatV2 != null
        && this.props.mainCatV2 instanceof Array
        && prevProps.mainCatV2.length != this.props.mainCatV2.length) {
      let allFilterData = {};
      let tempMainCatV2 = [...this.props.mainCatV2]
      let catV2 = JSON.parse(window.localStorage.getItem("catV2"))
    
      if (tempMainCatV2 != null) {
        tempMainCatV2.forEach(element => {
          allFilterData[element.node.wordpress_id] = []
        })
        tempMainCatV2.sort(this.compare_item)
      }

      if (catV2 != null) {
        catV2.forEach(element => {
          if (element.node.wordpress_parent != 0) {
            allFilterData[element.node.wordpress_parent].push(element.node)
          }
        })  
      }
    this.setState({
      allFilterData: allFilterData,
      catV2: catV2,
      mainCatV2: tempMainCatV2
    })

    }

    onLoadCat = JSON.parse(window.localStorage.getItem("onLoadCat"));
  }
 
    // Comparing based on the property item
    compare_item(a, b){
      // a should come before b in the sorted order
      if(a.node.wordpress_id < b.node.wordpress_id){
              return -1;
      // a should come after b in the sorted order
      }else if(a.node.wordpress_id > b.node.wordpress_id){
              return 1;
      // and and b are the same
      }else{
              return 0;
      }
    }

  componentDidMount() {
    this.setState({ loginstatus: window.sessionStorage.getItem("isLoggedIn") })
    if (window.sessionStorage.getItem("email")) {
      axios
        .post("https://dashboard.nativecircle.in/wp-json/api/login", { email: window.sessionStorage.getItem("email") })
        .then(response => {
          var name = (response.data.message[0].full_name).substr(0, 5) + '...'
          this.setState({
            // loggedinname:response.data.message[0].full_name
            loggedinname: name
          })
          // console.log(response);
        })
    }
    window.localStorage.setItem("category", "allData");
    // window.localStorage.setItem("isSelected", "false");
    window.localStorage.setItem("menuProduct", "");
    onLoadMainCatArray = [];
    window.addEventListener("scroll", this.listenScrollEvent);
    // this.headerActive();
    this.setState({ menuColor: "menuStickyColor" });
    // window.localStorage.setItem("category", "allData");
    // this.saveData();
    

    initialData.forEach(function (element) {
      // console.log(element)
      // alert(element.parent_element)
      if (
        element.node.parent_element == null &&
        onLoadMainCatArray.includes(element) == false
      ) {
        onLoadMainCatArray.push(element);
      }
    });
    let allFilterData = {};
    let mainCatV2 = JSON.parse(window.localStorage.getItem("mainCatV2"))
    let catV2 = JSON.parse(window.localStorage.getItem("catV2"))
    
    if (mainCatV2 != null) {
      mainCatV2.forEach(element => {
        allFilterData[element.node.wordpress_id] = []
      })
      mainCatV2.sort(this.compare_item)
    }

    if (catV2 != null) {
      catV2.forEach(element => {
        if (element.node.wordpress_parent != 0) {
          allFilterData[element.node.wordpress_parent].push(element.node)
        }
      })  
    }

    console.log(catV2)
   
    console.log(mainCatV2)
    this.setState({
      allFilterData: allFilterData,
      catV2: catV2,
      mainCatV2: mainCatV2
    })
  }

  listenScrollEvent = e => {
    if (window.scrollY > 150) {
      this.setState({
        headerClass: "headerSticky",
        displaySticky: "block",
        display: "none",
        navClassName: "navbar-toggleSticky",
        menuColor: "menuStickyColorOnScroll",
        menuStickySignup: "menuStickySignup",
        navbarClassName: "navbar-light",
        backgroundColor: "navbar-white "
      });
    } else {
      this.setState({
        headerClass: "headerbackgroundNew",
        displaySticky: "none",
        display: "block",
        navClassName: "navbar-toggle",
        menuColor: "menuStickyColor",
        menuStickySignup: "",
        navbarClassName: "navbar-dark",
        backgroundColor: "navbar-black "
      });
    }
  };

  mobilenoheader(headermobile) {
    window.localStorage.setItem("category", "allData");

    window.localStorage.setItem("menuCategory", "");

    window.localStorage.setItem("homePath", "");
    window.localStorage.setItem("MobileNumber", "");
    window.localStorage.setItem("headerPath", headermobile);
    // console.log(window.localStorage.getItem("headerPath"));
  }

  toggleMenu() {
    this.setState({ menu: !this.state.menu });
  }

  // componentDidMount() {
  //   const navbar = document.querySelector('#navbar')

  //   window.addEventListener('scroll', function (e) {
  //     const lastPosition = window.scrollY
  //     if (lastPosition > 480) {
  //       navbar.classList.add('headerOnScroll')
  //       // navbar.getElementsByClassName('nav-link').classList.add('textHeader');
  //     } else if (navbar.classList.contains('headerOnScroll')) {
  //       navbar.classList.remove('headerOnScroll')
  //     } else {
  //       navbar.classList.remove('headerOnScroll')
  //     }
  //   })

  // }

 

  render() {
    const show = this.state.menu ? "show" : "";

    return (
      <div className={this.state.headerClass}>
        <div className="header">
          <nav
            className={
              "navbar navbar-expand-lg static-top " + this.state.navbarClassName
            }
            id="navbar"
          >
            <div className="container ">
              <div className="col-12 col-sm-12 col-md-12 col-lg-1">
                <Link
                  to="/"
                  style={{ display: this.state.display, float: "left" }}
                >
                  <img
                    style={{
                      width:"54px",
                      height: "50px",
                      objectFit: "contain"
                    }}
                    src="https://res.cloudinary.com/dktnqgcmq/image/upload/v1630572338/Native%20Circle/logos/native_circle_logo-new-light.png"
                    className="image-margin-bottom"
                    id="desc-logo2"
                  />

                  <img
                    style={{
                      width:"54px",
                      height: "50px",
                      objectFit: "contain"
                    }}
                    src="https://res.cloudinary.com/dktnqgcmq/image/upload/v1630572338/Native%20Circle/logos/native_circle_logo-new-light.png"
                    className="image-margin-bottom"
                    id="mob-logo2"
                  />
                </Link>

                <Link
                  to="/"
                  style={{ display: this.state.displaySticky, float: "left" }}
                >
                  <img
                    style={{
                      width:"54px",
                      height: "50px",
                      objectFit: "contain"
                    }}
                    src="https://res.cloudinary.com/dktnqgcmq/image/upload/v1630571875/Native%20Circle/logos/native_circle_logo-new.png"
                    className="image-margin-bottom"
                    id="desc-logo1"
                  />

                  <img
                    style={{
                      width:"54px",
                      height: "50px",
                      objectFit: "contain"
                    }}
                    className="image-margin-bottom"
                    id="mob-logo1"
                    src="https://res.cloudinary.com/dktnqgcmq/image/upload/v1630571875/Native%20Circle/logos/native_circle_logo-new.png"
                  />
                </Link>

                <button
                  className="navbar-toggler"
                  type="button"
                  onClick={this.toggleMenu}
                  data-toggle="collapse"
                  data-target="#navbarResponsive"
                  aria-controls="navbarResponsive"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span className="navbar-toggler-icon" />
                </button>
              </div>
              <div
                className={
                  this.state.backgroundColor +
                  "  collapse navbar-collapse  " +
                  show + " header-nav-bar-container"
                }
                id="navbarResponsive"
              >
                <ul className="navbar-nav responsive-product-menu">
                  <li className=" native_Menu nav-item menu toppad">
                    <Link
                      className={this.state.menuColor}
                      to="/how-it-works/"
                      activestyle={{ color: "#F2BE53" }}
                    >
                      How it works
                    </Link>
                  </li>

                  <li className=" native_Menu nav-item menu toppad">
                    <Link
                      className={this.state.menuColor}
                      to="/blog/"
                      activestyle={{ color: "#F2BE53" }}
                    >
                      Blogs
                    </Link>
                  </li>

                  {/* Disabling products */}
                  {/* <li className="NC_native_MenuMob toppadMob NC_native_Menu nav-item mobile-only">
                    <Link
                      // className="nav-link"
                      className={this.state.menuColor}
                      to="/products/"
                      activestyle={{ color: "#F2BE53" }}
                    >
                      Products
                    </Link>
                  </li> */}

                  {/* <li className="native_Menu nav-item toppad desktop-only">
                    <div className="dropdown">
                      {" "}
                      <Link
                        activestyle={{ color: "#F2BE53" }}
                        className={this.state.menuColor}
                        to="/products/"
                        onClick={() => this.mobilenoheader("headermobile")}
                      >
                        Products <i className="fa fa-caret-down" />
                      </Link>
                      <div className="dropdown-content" id="productsDD">
                        <div className="col-sm-12">
                          {
                          this.state.allFilterData != null 
                            && this.state.mainCatV2 != null
                            && this.state.mainCatV2.length != 0
                            ? this.state.mainCatV2.map(( node , i) => {
                              console.log(node)
                              var catWpId = node.node.wordpress_id;
                              var imgSrc = imgArray[i];
                              let filterData = this.state.allFilterData[catWpId];
                              return (
                                <div
                                  className=" col-sm-4 agriFloatForestry"
                                  key={i}
                                >
                                  <a
                                    className="agriPad30"
                                    href="/products/"
                                    onClick={() => {
                                      window.localStorage.setItem(
                                        "mainCategoryId",
                                        node.node.wordpress_id
                                      );
                                    }}
                                  >
                                    {node.node.name}
                                  </a>
                                  <div>
                                    <ul className="agriProdList ">
                                      {filterData != null 
                                        ? filterData.map(( subNode , i) => {
                                            return (
                                              <li
                                                className="headerList"
                                                key={i}
                                              >
                                                <a
                                                  href="/products/"
                                                  onClick={() => {
                                                    window.localStorage.setItem(
                                                      "subCategoryId",
                                                      subNode.wordpress_id
                                                    );
                                                  }}
                                                >
                                                  {subNode.name}
                                                </a>
                                              </li>
                                            );
                                        })
                                        : null}
                                    </ul>
                                  </div>
                                </div>
                              );
                            })
                            : null}
                        </div>
                      </div>
                    </div>
                  </li> */}


                  <li className=" native_Menu nav-item toppad">
                    <Link
                      className={this.state.menuColor}
                      to="/farmers/"
                      activestyle={{ color: "#F2BE53" }}
                    >
                      Workshops
                    </Link>
                  </li>

                  {/* Disabling products */}
                  {/* <li className=" native_Menu_Business native_Menu nav-item toppad">
                    <Link
                      className={this.state.menuColor}
                      to="/business/"
                      activestyle={{ color: "#F2BE53" }}
                    >
                      For Bulk-Orders
                    </Link>
                  </li> */}

                  <li className=" native_Menu_Business native_Menu nav-item toppad">
                    {/* <a
                      className={this.state.menuColor}
                      href="https://medium.com/native-circle"
                      activestyle={{ color: "#F2BE53" }}
                      target="_blank"
                    >
                      Blog
                    </a> */}

                    <Link
                      className={this.state.menuColor}
                      to="/about-us/"
                      activestyle={{ color: "#F2BE53" }}
                    >
                      About Us
                    </Link>

                  </li>
                  <li className=" native_Menu_Business native_Menu nav-item toppad">
                    <Link
                      className={this.state.menuColor}
                      to="/work-with-us/"
                      activestyle={{ color: "#F2BE53" }}
                    >
                      Join Us
                    </Link>
                  </li>

                  {/* <div style={{
                        display:this.state.logindisplay
                      }}>
                      <li className=" native_Menu_Business native_Menu nav-item">
                      <Link to="/login/">
                       <button  className="loginbutton">
                        Login
                        </button>
                       </Link>
                      </li>
                      </div> */}
                  {/* </div>
                <div  style={{
                        display:this.state.profiledisplay
                      
                      }}>
                       <li className=" native_Menu_Business native_Menu nav-item"> 
                       <div className="dropdown profile">
                      
                       <img src="https://res.cloudinary.com/djoztpm50/image/upload/c_scale,h_44,w_44/v1568376396/profile_new_zd6qdb.png"
                       class="profileimage" >
                       </img>
                       
                        <i className="fa fa-caret-down" style={{color:"white",paddingLeft:"5px",fontSize:"20px"}} />

                       <div className="dropdown-content profile_drop_down_content">
                       <ul>
                                
                              <li><a  href="/profile/">Profile</a></li>
                              <li><a  href="/order_details/">Orderdetails</a></li>
                              <li> <a  class="dropdownborder" href="/" onClick={this.logout}>Logout</a></li>
                            
                       </ul>
                      
                       </div> 


                             
                       
                       </div>
                      
                     
                        </li>
                       
                        </div> */}
                </ul>
                
                {this.state.loginstatus == "true" ? (
                    <div>
                        <div className="dropdown profile">

                          {/* <img src="https://res.cloudinary.com/djoztpm50/image/upload/c_scale,h_44,w_44/v1568376396/profile_new_zd6qdb.png"
                      class="profileimage" >
                      </img> */}
                          <Link
                            className={this.state.menuColor}
                            activestyle={{ color: "#F2BE53" }}
                          >

                            {this.state.loggedinname} <i className="fa fa-caret-down" />
                          </Link>



                          <div className="dropdown-content profile_drop_down_content">
                            <ul>
                              <li><a href="/profile/">Profile</a></li>
                              <li><a href="/order_details/">Order details</a></li>
                              <li> <a href="/" onClick={this.logout}>Logout</a></li>


                            </ul>

                          </div>

                        </div>
                    </div>
                  ) : (
                      <div>
                          <Link to="/login/">
                            <button className="loginbutton">
                              Login
                        </button>
                          </Link>
                      </div>
                    )}

              </div>
            </div>
          </nav>
        </div>
      </div>
    );
  }
}

export default Header;
