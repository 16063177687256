import { Link } from "gatsby";
import PropTypes from "prop-types";
import React from "react";
require("./footer.css");

class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className="FooterLayout col-sm-12">
        <div className="container pad0">
          <div className="col-sm-12 FooterMain row pad0 marg0">
            <div className="col-sm-6  col-md-6 col-xs-12  FooterLeft ">
              <div className="col-sm-12 row marg0 FooterLeft">
                <div className="col-sm-3 pad0 FooterImg-Class">
                  <img
                    style={{
                      width: "81px",
                      height : "75px"
                    }}
                    className="FooterImg"
                    src="https://res.cloudinary.com/dktnqgcmq/image/upload/v1630571875/Native%20Circle/logos/native_circle_logo-new.png"
                  />
                </div>
                <div className="col-sm-9 footerRight">
                  <p className="NativeMenuTab">
                    <span className="nativeSpan">NATIVE</span> CIRCLE
                  </p>
                  <p className="aboutNativeFoot">
                    Native circle is a conscious effort to go back to the
                    original values and traditions which once shaped the value
                    system of India. <br /> Espousing the natural way of
                    life and living, Native Circle aims to integrate dispersed
                    expertise, successful practices and its own experimental
                    results under one roof to develop an instructive model.
                    Nature education, agriculture, forestry, food, and
                    value-added products being the areas of focus.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-md-6 col-xs-12 footerRight">
              <div className="col-sm-12 row marg0 pad0">
                <div className="col-sm-6 col-md-6 col-xs-12">
                  <p className="NativeMenuTab">Information</p>
                  <ul className="footerMenuItems">
                    <li>
                      <Link to="/about-us/">About NativeCircle</Link>
                    </li>
                    <li>
                      <Link to="/how-it-works/">How it works</Link>
                    </li>
                    <li>
                      <Link to="/shipping-policy/">Shipping Policy</Link>
                    </li>
                    <li>
                      <Link to="/privacy-policy/">Privacy Policy</Link>
                    </li>
                    <li>
                      <Link to="/terms-and-condition/">Terms & Conditions</Link>
                    </li>
                    <li>
                      <Link to="/cancellationPolicy/">Cancellation Policy</Link>
                    </li>
                  </ul>
                </div>
                {/*  <div className="col-sm-3 pad0">
                  <p className="NativeMenuTab">My Account</p>
                  <ul className="footerMenuItems">
                       <li><a href="">My Account</a></li> 
                   <li>
                      <a href="">My Orders</a>
                    </li>
                    <li>
                      <a href="/myCart">My Cart</a>
                    </li>
                    <li>
                      <a href="">My Wishlist</a>
                    </li>
                    <li>
                      <a href="">Checkout</a>
                    </li>
                  </ul>
                </div> */}
                <div className="col-sm-6 col-md-6 col-xs-12 pad0 footerLinks">
                  <p className="NativeMenuTab">Contact Us</p>
                  <div className="footerSocialLinks">
                    <a
                      href="https://www.facebook.com/nativecircle.live/"
                      target="_blank"
                    >
                      {/* <img src="https://res.cloudinary.com/djoztpm50/image/upload/v1551084679/Native%20Circle/facebook-logo-in-circular-button-outlined-social-symbol.png" /> */}
                      <i className="fa fa-facebook-f social-icons-footer fbftr" />
                    </a>
                    <a
                      href="https://www.instagram.com/native_circle/?hl=en"
                      target="_blank"
                    >
                      {/* <img src="https://res.cloudinary.com/djoztpm50/image/upload/v1551084696/Native%20Circle/group-49.png" />*/}
                      <i className="fa fa-instagram social-icons-footer instaftr" />
                    </a>
                    {/* <a href="" target="_blank">
                      <img src="https://res.cloudinary.com/djoztpm50/image/upload/v1551084732/Native%20Circle/social-twitter-circular-button.png" />
                    </a> */}
                    <a
                      href="https://api.whatsapp.com/send?phone=917338388575"
                      target="_blank"
                    >
                      {/* <img src="https://res.cloudinary.com/djoztpm50/image/upload/v1551084765/Native%20Circle/whatsapp.png" />*/}
                      <i className="fa fa-whatsapp social-icons-footer watsupftr" />
                    </a>
                    {/*    <div className="contNum">
                      <a href="https://api.whatsapp.com/send?phone=917338388575">
                        +91 7338388575
                      </a>
                    </div> */}
                  </div>
                  <div className="contNum">
                    Or Call Us : <a href="tel:+917338388575">+91 7338388575</a>
                  </div>
                  <div className="contNum">8:00 AM - 6:00 PM (MON - SAT)</div>
                  <div className="contNum">Address: Hemagaru, <br />
                  Siddapura, North Kanara, <br />
                  Karnataka - 581329
                  </div>

                </div>
              </div>
            </div>
          </div>


          {/* Anil code */}
          <div className="mainmastercrddiv">


            <div id="mastercardimg">
              <ul>
                <li>  <img id="mastericonpd" src="https://res.cloudinary.com/djoztpm50/image/upload/v1573034028/maestercrd_azjble.png" alt="" /></li>

                <li>  <img id="mastericonpd" src="https://res.cloudinary.com/djoztpm50/image/upload/v1573034096/visa_eoakka.png" alt="" /></li>

                <li>  <img id="mastericonpd" src="https://res.cloudinary.com/djoztpm50/image/upload/v1573034076/mastercard.pnp_muzbjx.png" alt="" /></li>
              </ul>
            </div>
          </div>


          {/* end code */}








          <p className="text-center">
            © 2020 NativeCircle. All rights reserved
          </p>
        </div>
      </div>
    );
  }
}

export default Footer;
